import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker/models';
import { BsDatepickerInlineDirective, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { TranslateService } from '@ngx-translate/core';
import { IGroupInterview, IInterview, InterviewStatus, TimeSlot } from 'src/app/model/interview.interface';
import { InterviewService } from 'src/app/services/interview.service';
import { LoaderService } from 'src/app/services/loader.service';
import { catchError, forkJoin, Observable, of, Subject, takeUntil } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlingService } from 'src/app/services/handle-error.service';
import { GroupInterviewService } from 'src/app/services/group-interview.service';
import { ENTERPRISE_ROLES } from 'src/app/resources/roles';
import { UserService } from 'src/app/services/user.service';
import { InterviewForCalendar } from 'src/app/classes/interviewForCalendar.class';
import { SetupService } from 'src/app/services/setup.service';
import { BsDatePickerUtils } from 'src/app/model/bs-date-picker.interface';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarComponent implements OnInit {
  @Input() isEventPicker: boolean;
  @Input() minDate: Date;
  @Input() selectedDate: Date;

  @Output() selectedDateChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(BsDatepickerInlineDirective) datePicker: BsDatepickerInlineDirective;

  bsDatePickerUtils: BsDatePickerUtils;
  datePickerInitialized: boolean;
  receivedMonths: any[] = [];
  bsInlineValue: Date;
  bsValueChangedInCode: boolean;
  dateCustomClasses: DatepickerDateCustomClasses[] = [];
  eventList: InterviewForCalendar[] = [];
  receivedEventList: InterviewForCalendar[] = [];
  eventListForDate: InterviewForCalendar[] = [];
  pendingEventList: InterviewForCalendar[] = [];
  pendingEventListForDate: InterviewForCalendar[] = [];
  displayCalendar: boolean;
  today = new Date();

  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private localeService: BsLocaleService,
    private interviewService: InterviewService,
    private groupInterviewService: GroupInterviewService,
    private cdr: ChangeDetectorRef,
    private loaderService: LoaderService,
    private errorHandlingService: ErrorHandlingService,
    private userService: UserService,
    private setupService: SetupService,
  ) { }

  ngOnInit(): void {
    const currentLanguage = this.translateService.currentLang;
    this.localeService.use(currentLanguage);
    if (this.setupService.areInterviewsEnabled) {
      this.getEvents();
    } else {
      this.onReceivedData();
    }
  }

  setEnabledLinkTime(event: TimeSlot, status: string): boolean {
    const enabledLinkTime = new Date();
    enabledLinkTime.setTime(event.end.getTime() + (30*60*1000));
    return enabledLinkTime > this.today &&  status !== 'finished';
  }

  getEvents(): void {
    this.loaderService.show();

    const getForDate = this.selectedDate || this.today;

    const firstDay = new Date(getForDate.getFullYear(), getForDate.getMonth(), 1);
    const lastDay = new Date(getForDate.getFullYear(), getForDate.getMonth() + 1, 1);

    const confirmedInterviews$: Observable<IInterview[]> = this.setupService.areInterviewsEnabled ?
      this.interviewService.getVideoInterviewsForCustomer(InterviewStatus.confirmed, {startDate: firstDay, endDate: lastDay}) : of([]);
      const finishedInterviews$: Observable<IInterview[]> = this.setupService.areInterviewsEnabled ?
      this.interviewService.getVideoInterviewsForCustomer(InterviewStatus.finished, {startDate: firstDay, endDate: lastDay}) : of([]);
    const pendingInterviews$: Observable<IInterview[]> = this.setupService.areInterviewsEnabled && this.isEventPicker ?
      this.interviewService.getVideoInterviewsForCustomer(InterviewStatus.pending) : of([]);
    const groupInterviews$: Observable<IGroupInterview[]> = this.setupService.areInterviewsEnabled ?
      this.groupInterviewService.getGroupVideoInterviews({startDate: firstDay, endDate: lastDay}) : of([]);


    forkJoin([confirmedInterviews$, finishedInterviews$, pendingInterviews$, groupInterviews$])
      .pipe(
        catchError((errorResponse: HttpErrorResponse) =>
          this.errorHandlingService.handleBackendError(errorResponse)
        )
      )
      .subscribe(([confirmedInterviews, finishedInterviews, pendingInterviews, groupInterviews]) => {
        const [confirmedGroupInterviews, finishedGroupInterviews, pendingGroupInterviews] = this.prepareGroupInterviews(groupInterviews);

        this.eventList = [];
        this.receivedEventList = [];
        this.receivedMonths.push(firstDay);
        confirmedInterviews.forEach(interview => this.receivedEventList.push(new InterviewForCalendar(interview)));
        finishedInterviews.forEach(interview => this.receivedEventList.push(new InterviewForCalendar(interview)));
        this.receivedEventList.push(...confirmedGroupInterviews);
        this.receivedEventList.push(...finishedGroupInterviews);
        this.eventList.push(...this.receivedEventList);
        this.pendingEventList = [];

        // if calendar is on add-event section then pendingInterviews will be displayed
        // and if calendar is on dashboard page then dates with event will be marked
        if (this.isEventPicker) {
          pendingInterviews.forEach(interview => this.pendingEventList.push(new InterviewForCalendar(interview)));
          this.pendingEventList.push(...pendingGroupInterviews);
        } else {
          this.markDaysWithEvents();
        }

        this.onReceivedData();
        this.loaderService.hide();
      });
  }

  onReceivedData(): void {
    this.displayCalendar = true;
    this.cdr.detectChanges();
    this.selectedDate = this.selectedDate ? new Date(this.selectedDate) : new Date();
    this.bsValueChangedInCode = true;
    this.bsInlineValue = this.selectedDate;
    this.selectedDateChange.emit({selectedDate: this.selectedDate, eventListForDate: []});
    this.subscribeToChangeOfMonth();
  }

  subscribeToChangeOfMonth(): void {
    this.bsDatePickerUtils = new BsDatePickerUtils(this.datePicker);
    this.bsDatePickerUtils.viewChanged
    .pipe(
      takeUntil(this._ngUnsubscribe$),
      catchError((error: HttpErrorResponse) =>
        this.errorHandlingService.handleBackendError(error)
      )
    ).subscribe(date => {
      this.loaderService.show();
      const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
      if (!this.datePickerInitialized) {
        this.datePickerInitialized = true;
        this.loaderService.hide();
        return;
      }
      if (this.receivedMonths.findIndex(month => month.toISOString() === startOfMonth.toISOString()) > -1) {
        if (this.selectedDate.toISOString() !== startOfMonth.toISOString()) {
          this.setInlineValue(startOfMonth);
        } else {
          this.loaderService.hide();
        }
        return;
      }
      this.getConfirmedInterviewsForNewMonth(date);
    });
  }

  getConfirmedInterviewsForNewMonth(date: Date): void {
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    const confirmedInterviews$: Observable<IInterview[]> =
      this.interviewService.getVideoInterviewsForCustomer(InterviewStatus.finished, {startDate: firstDay, endDate: lastDay});
    const groupInterviews$: Observable<IGroupInterview[]> =
      this.groupInterviewService.getGroupVideoInterviews({startDate: firstDay, endDate: lastDay});

    forkJoin([confirmedInterviews$, groupInterviews$])
    .pipe(
      catchError((errorResponse: HttpErrorResponse) =>
        this.errorHandlingService.handleBackendError(errorResponse)
      )
    )
    .subscribe(([confirmedInterviews, groupInterviews]) => {
      const [confirmedGroupInterviews, pendingGroupInterviews] = this.prepareGroupInterviews(groupInterviews);

      this.receivedEventList = [];
      this.receivedMonths.push(firstDay);
      confirmedInterviews.forEach(interview => this.receivedEventList.push(new InterviewForCalendar(interview)));
      this.receivedEventList.push(...confirmedGroupInterviews);
      this.eventList.push(...this.receivedEventList);
      this.pendingEventList.push(...pendingGroupInterviews);
      // if calendar is on dashboard page then dates with event will be marked
      if (!this.isEventPicker) {
        this.markDaysWithEvents();
      }
      if (!this.isEventPicker) {
        this.selectedDateChange.emit({selectedDate: this.selectedDate ? new Date(this.selectedDate) : new Date(), eventListForDate: []});
      }
      this.setInlineValue(firstDay);
    });
  }

  setInlineValue(date: Date): void {
    this._ngUnsubscribe$.next();
    this.datePickerInitialized = false;
    this.selectedDate = date.getMonth() === this.minDate?.getMonth() ? this.minDate : date;
    this.bsValueChangedInCode = true;
    this.bsInlineValue = this.selectedDate;
    this.cdr.detectChanges();
    this.subscribeToChangeOfMonth();
    this.loaderService.hide();
  }

  prepareGroupInterviews(groupInterviews: IGroupInterview[]): InterviewForCalendar[][] {
    const confirmedGroupInterviews = [];
    const finishedGroupInterviews = [];
    const pendingGroupInterviews = [];
    const isEnterpriseUser = ENTERPRISE_ROLES.includes(this.userService.role);
    const property = isEnterpriseUser ? 'enterpriseManagersInGroupInterview' : 'customersInGroupInterview';

    groupInterviews.forEach(interview => {
      const employee = interview[property].find(enManager => enManager.employee.id === this.userService.userId);
      if (employee) {
        if (employee.status === InterviewStatus.created || employee.status === InterviewStatus.confirmed) {
          confirmedGroupInterviews.push(new InterviewForCalendar(interview, employee.linkToInterview));
        } else if (employee.status === InterviewStatus.pending) {
          pendingGroupInterviews.push(new InterviewForCalendar(interview, employee.linkToInterview));
        } else if (employee.status === InterviewStatus.finished) {
          finishedGroupInterviews.push(new InterviewForCalendar(interview, employee.linkToInterview));
        }
      }
    });
    return [confirmedGroupInterviews, finishedGroupInterviews, pendingGroupInterviews];
  }

  getEventsForSelectedDate(): void {
    this.selectedDate.setHours(0,0,0,0);
    this.eventListForDate = this.eventList?.filter(event =>
      new Date(event.timeSlot.start).setHours(0,0,0,0) === this.selectedDate.setHours(0,0,0,0)
    );

    if (this.isEventPicker) {
      this.pendingEventListForDate = [];
      JSON.parse(JSON.stringify(this.pendingEventList)).forEach(event => {
        event.timeSlots.forEach(timeSlot => {
          if (new Date(timeSlot.start).setHours(0,0,0,0) === this.selectedDate.setHours(0,0,0,0)) {
            const eventClone = JSON.parse(JSON.stringify(event));
            eventClone.timeSlots = [];
            eventClone.timeSlot = {start: new Date(timeSlot.start), end: new Date(timeSlot.end)};
            if (this.pendingEventListForDate.findIndex(pendingEvent =>
                     pendingEvent.timeSlot.start.toISOString() === eventClone.timeSlot.start.toISOString()) < 0 ) {
              this.pendingEventListForDate.push(eventClone);
            }
          }
        });
      });
      this.pendingEventListForDate
        .sort((a,b) => (a.timeSlot.start > b.timeSlot.start) ? 1 : ((b.timeSlot.start > a.timeSlot.start) ? -1 : 0));
    }

    this.eventListForDate.sort((a,b) => (a.timeSlot.start > b.timeSlot.start) ? 1 : ((b.timeSlot.start > a.timeSlot.start) ? -1 : 0));

    this.cdr.detectChanges();
    this.selectedDateChange.emit({selectedDate: this.selectedDate,
                                  eventListForDate: this.eventListForDate,
                                  pendingEventListForDate: this.pendingEventListForDate});
  }

  markDaysWithEvents(): void {
    this.receivedEventList.forEach(event => {
      const date: Date = event.timeSlot.start;
      this.dateCustomClasses.push({date, classes: ['custom-date-with-event']});
    });
  }

  onSelectedDateChange(date: Date): void {
    if (this.bsValueChangedInCode) {
      this.bsValueChangedInCode = false;
    } else {
      this._ngUnsubscribe$.next();
      this.datePickerInitialized = false;
      this.selectedDate = date;
      this.subscribeToChangeOfMonth();
    }
    if (this.setupService.areInterviewsEnabled) {
      this.getEventsForSelectedDate();
    } else {
      if (!this.isEventPicker) {
        this.cdr.detectChanges();
        this.selectedDateChange.emit({selectedDate: this.selectedDate, eventListForDate: []});
      }
    }
  }
}

