<section class="contact-us-container">
  <header class="contact-us-header">
    <h3 class="contact-us-title">{{ 'SHARED.CONTACT_US_TITLE' | translate }}</h3>
  </header>

  <div class="form-container">
    <form class="contact-us-form"
          [formGroup]="contactUsForm"
          (ngSubmit)="onSubmit()"
          novalidate>

      <div class="form-group">
        <select formControlName="emailSubject"
            class="form-control select-subject"
            [ngClass]="{
              'is-invalid': !emailSubject.valid && emailSubject.touched
            }">
          <option value="null">{{ 'SHARED.CHOOSE_SUBJECT' | translate }}</option>
          <option *ngFor="let subject of subjects" [value]="subject.id">
            {{ subject.name[activeLanguage] }}
          </option>
        </select>
        <div class="invalid-feedback">{{ 'SHARED.CHOOSE_SUBJECT_ERROR_MSG' | translate }}</div>
      </div>

      <app-phone-edit *ngIf="phone" [form]="contactUsForm" [defaultCountry]="currentCountry.name"></app-phone-edit>

      <div class="form-group">
        <textarea id="message"
               class="form-control contact-us-message"
               formControlName="content"
               placeholder="{{ 'SHARED.TEXT_MESSAGE_PLACEHOLDER' | translate }}"
               [ngClass]="{
                 'is-invalid': !content.valid && content.touched
               }">
          </textarea>
        <div class="invalid-feedback">{{ 'SHARED.TEXT_MESSAGE_ERROR_MSG' | translate }}</div>
      </div>

      <div class="contact-us-actions-wrapper">
        <button class="btn-add-attach btn-primary"
                type="button"
                (click)="fileUpload.click($event)">
          <i class="icon-attach"></i>
        </button>
        <input #fileUpload
                type="file"
                (change)="uploadFile($event.target)"
                [accept]="allowedImageAndDocumentFormats.join(', ')"
                multiple
                hidden />
        <button class="btn btn-primary btn-send"
                type="submit"
                role="button">{{ 'SHARED.SEND_BUTTON' | translate }}</button>
      </div>
      <div class="files-list-wrapper">
        <div *ngFor="let file of files.value; index as i"
              class="file-list-item">
           <p class="file-item-name">{{ file.name }}</p>
           <div class="file-action-wrapper">
              <button class="btn-reset-file"
                      (click)="deleteFile(i)"></button>
           </div>
        </div>
      </div>
    </form>

  </div>

</section>
