<div class="chart-container">
  <div class="chart-wrapper" [ngClass]="{'test': !test}">
    <header *ngIf="test">
      <h3 class="chart-title">{{title}}</h3>
    </header>
    <div class="donut-graph">
      <div class="svg-container">
        <div class="mouse-position-tooltip"
             *ngIf="showTooltip"
             [ngStyle]="{'top.px': tooltipTop, 'left.px': tooltipLeft}">
          <div class="tooltip-square"
               [ngStyle]="{'backgroundColor': legendColor }"></div>
          <p class="tooltip-text">{{legendName}}</p>
        </div>
        <svg [attr.width]="width"
        [attr.height]="height"
             viewBox="0 0 42 42"
             class="chart">
          <circle *ngIf="config[0].percentage !== 10000"
                  cx="21"
                  cy="21"
                  r="15.91549430918954"
                  stroke-width="2"
                  stroke="#e5e5e5"
                  [style.stroke]="config[1]?.color"
                  stroke-linecap="round"
                  fill="none"
                  (mouseenter)="mouseEnter(config[1]?.color, config[1]?.name)"
                  (mouseleave)="mouseLeave()"/>
          <circle *ngIf="config[0].percentage !== 10000"
                  [style.stroke-dasharray]="config[0].percentage + ' ' + (100 - config[0].percentage)"
                  stroke-dashoffset="25"
                  cx="21"
                  cy="21"
                  r="15.91549430918954"
                  stroke-width="2"
                  [style.stroke]="config[0].color"
                  stroke-linecap="round"
                  fill="none"
                  (mouseenter)="mouseEnter(config[0].color, config[0].name)"
                  (mouseleave)="mouseLeave()" />
        </svg>
        <div *ngIf="textCenter" [ngClass]="{'small': this.config[0].percentage === 10000}" class="graphText">{{centerGraphText}}</div>
      </div>
    </div>
    <app-chart-legend *ngIf="legend" [config]="config"></app-chart-legend>
  </div>
</div>
