import { NgStyle, NgClass, NgFor } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { interval, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-custom-carousel',
  templateUrl: './custom-carousel.component.html',
  styleUrls: ['./custom-carousel.component.scss'],
  imports: [NgStyle, NgClass, NgFor],
  standalone: true
})
export class CustomCarouselComponent implements OnInit, OnDestroy {
  @Input() images: string[];
  currentSlide: number;
  nextSlide: number;
  prevSlide: number;
  moving: boolean;

  protected _ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor() { }

  ngOnInit(): void {
    this.initializeCarousel();
    this.startSliding();
  }

  initializeCarousel(): void {
    this.currentSlide = 0;
    // If carousel has only two items then only nextSlide should be set
    if (this.images.length > 2 ) {
      this.prevSlide = this.images.length - 1;
    }
    this.nextSlide = 1;
    // Set moving to false so that the carousel becomes interactive
    this.moving = false;
  }

  startSliding(): void {
    const timer$ = interval(2500);
    timer$
      .pipe(
        takeUntil(this._ngUnsubscribe$),
      ).subscribe(() => {
        this.moveNext();
      });
  }

  pause(): void {
    this._ngUnsubscribe$.next();
  }

  onMouseLeave(): void {
    this.startSliding();
  }

  // Next navigation handler
  moveNext(): void {
    // Check if moving
    if (!this.moving) {
      if (this.currentSlide === (this.images.length - 1)) {
        this.currentSlide = 0;
      } else {
        this.currentSlide++;
      }
      // Move carousel to updated slide
      this.moveCarouselTo(this.currentSlide);
    }
  }

  disableInteraction(): void {
    // Set 'moving' to true for the same duration as our transition. (0.5s = 500ms)
    this.moving = true;
    // setTimeout runs its function once after the given time
    setTimeout(() => {
      this.moving = false;
      // if carousel has only two items then prevSlide should change its class after moving is finished to nextSlide
      if (this.images.length === 2 ) {
        this.prevSlide = null;
        this.nextSlide = this.currentSlide + 1;
        if (this.nextSlide > this.images.length - 1) {
          this.nextSlide = 0;
        }
      }
    }, 500);
  }

  onInidicatorClicked(slide: number): void {
    // Check if moving
    if (!this.moving) {
      this.currentSlide = slide;
      this.moveCarouselTo(this.currentSlide);
    }
  }

  moveCarouselTo(slide: number): void {
    // Check if carousel is moving, if not, allow interaction
    if(!this.moving) {
      // temporarily disable interactivity
      this.disableInteraction();
      this.nextSlide = null;
      this.prevSlide = slide - 1;
      // Checks and updates if slide is at the beginning/end
      if (this.prevSlide < 0) {
        this.prevSlide = this.images.length - 1;
      }
      // If carousel has three items then nextSlide should be set
      if (this.images.length > 2 ) {
        this.nextSlide = slide + 1;
        if (this.nextSlide > this.images.length - 1) {
          this.nextSlide = 0;
        }
      }
    }
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
