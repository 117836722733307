import { Component, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { LoaderService } from 'src/app/services/loader.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SUPPORT_EMAIL_FILE_SIZE_LIMIT } from 'src/app/resources/upload-resources';
import { ALLOWED_IMAGE_AND_DOCUMENT_FORMATS, ALLOWED_IMAGE_AND_DOCUMENT_EXTENSIONS } from 'src/app/resources/allowed-file-formats';
import { UniversalOption } from 'src/app/model/universal-option.interface';
import { SetupService } from 'src/app/services/setup.service';
import { Country } from 'src/app/model/company.interface';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { PhoneEditComponent } from '../phone-edit/phone-edit.component';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
  imports: [NgClass, TranslateModule, ReactiveFormsModule, NgIf, PhoneEditComponent, NgFor],
  standalone: true
})
export class ContactUsComponent {

  @Input() contactUsForm: FormGroup;
  @Input() subjects: UniversalOption[];

  @Output() sendEmail: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  activeLanguage: string = this.translateService.currentLang;
  currentCountry: Country = this.setupService.currentCompany.location.country;
  allFilesSize = 0;

  readonly allowedImageAndDocumentFormats = ALLOWED_IMAGE_AND_DOCUMENT_FORMATS;

  constructor(
    private loaderService: LoaderService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private fb: FormBuilder,
    private setupService: SetupService
  ) { }

  get files(): FormArray {
    return this.contactUsForm.get('files') as FormArray;
  }

  get phone(): FormControl {
    return this.contactUsForm.get('phone') as FormControl;
  }

  get networks(): FormControl {
    return this.contactUsForm.get('networks') as FormControl;
  }

  get content(): FormArray {
    return this.contactUsForm.get('content') as FormArray;
  }

  get emailSubject(): FormControl {
    return this.contactUsForm.get('emailSubject') as FormControl;
  }

  onSubmit(): void {
    this.contactUsForm.markAllAsTouched();

    if (this.contactUsForm.valid) {
      this.sendEmail.emit();
    }
  }

  uploadFile(fileInput: HTMLInputElement): void {
    const files = fileInput.files;

    if ((this.files.controls.length + files.length) > 3) {
      this.toastr.error(
        this.translateService.instant(
          'SHARED.UPLOAD_MORE_THAN_PLURAL_FILES_NOT_ALLOWED',
          { number: 3 }
        )
      );
      fileInput.value = null;
      return;
    }

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < files.length; i++) {
      const file: File = files[i];

      if (!file) {
        fileInput.value = null;
        return;
      }

      if (file.type === '') {
        const extension = file.name.substring(file.name.lastIndexOf('.'));

        if (!(ALLOWED_IMAGE_AND_DOCUMENT_EXTENSIONS.includes(extension))) {
          this.toastr.error(this.translateService.instant('SHARED.UPLOAD_FILE_TYPE_NOT_ALLOWED'));
          fileInput.value = null;
          return;
        }
      }

      if (!ALLOWED_IMAGE_AND_DOCUMENT_FORMATS.includes(file.type)) {
        this.toastr.error(this.translateService.instant('SHARED.UPLOAD_FILE_TYPE_NOT_ALLOWED'));
        fileInput.value = null;
        return;
      }

      if ((file.size + this.allFilesSize) > SUPPORT_EMAIL_FILE_SIZE_LIMIT) {
        this.toastr.error(
          this.translateService.instant('SHARED.FILE_SIZE_SMALLER_THAN')
        );
        fileInput.value = null;
        return;
      }


      if (this.files.controls.some((element) => element.value.name === file.name)) {
        this.toastr.error(
          this.translateService.instant(
            'SHARED.FILE_ALREADY_UPLOADED',
            { fileName: file.name }
          )
        );
        fileInput.value = null;
        return;
      }

      this.loaderService.show();

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.files.push(this.fb.control({ value: reader.result, name: file.name, size: file.size }));
        this.allFilesSize += file.size;
        this.loaderService.hide();
      };
    }

    fileInput.value = null;
  }

  deleteFile(index: number): void {
    this.allFilesSize -= this.files.controls[index].value.size;
    this.files.removeAt(index);
  }
}
