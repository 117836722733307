import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-chart-legend',
  templateUrl: './chart-legend.component.html',
  styleUrls: ['./chart-legend.component.scss']
})
export class ChartLegendComponent {
  @Input() config;
  @Input() standAlone;
  @Input() title;

  constructor() {}

}
